import { RequestInfo } from "../hooks/useRequests";
import client, { getFailedResponse, processResponse } from "./client";

export const endpoint = "/requests";

const create = async (request: RequestInfo) => {
  try {
    return processResponse(await client.post(endpoint, request));
  } catch (error) {
    return getFailedResponse(error);
  }
};

const deleteRequest = (requestId: string) =>
  client.delete(`${endpoint}/${requestId}`);

const get = async () => {
  try {
    return processResponse(await client.get(endpoint));
  } catch (error) {
    return getFailedResponse(error);
  }
};

const getRequest = (requestId: string) =>
  client.get(`${endpoint}/${requestId}`);

const getUserRequests = (userId: string) => client.get(`${endpoint}/${userId}`);

const update = (requestInfo: RequestInfo, requestId: string) =>
  client.put(`${endpoint}/${requestId}`, requestInfo);

export default {
  create,
  deleteRequest,
  get,
  getRequest,
  getUserRequests,
  update,
};
