import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import accounts from "../services/accounts";
import ProductForm from "../components/products/Form";

const NewProductPage = () => {
  const navigate = useNavigate();
  const [shopId, setShopId] = useState("");

  useEffect(() => {
    const activeShopAccount = accounts.getAcitveAccount();
    if (!activeShopAccount) navigate("/");
    else setShopId(activeShopAccount.id);
  }, []);

  return (
    <div className="px-5">
      <ProductForm onDone={() => navigate("/")} shopId={shopId} />
    </div>
  );
};

export default NewProductPage;
