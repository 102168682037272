import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Category } from "../hooks/useCategories";
import { DataError } from "../services/client";
import {
  ErrorMessage,
  Form,
  FormField,
  SubmitButton,
} from "../components/form";
import { Listing } from "../hooks/useListings";
import { useImages, useListings, useUser } from "../hooks";
import CategorySelect from "../components/listings/CategorySelect";
import ImageInputList from "../components/common/ImageInputList";
import service from "../services/listings";
import storage from "../db/image";

const schema = Yup.object().shape({
  price: Yup.number().min(1).max(1_000_000).required(),
  description: Yup.string(),
  title: Yup.string().min(3).required(),
  phone: Yup.string(),
});

type NewLisitingInfo = Yup.InferType<typeof schema>;

const MAX_IMAGES = 3;

interface Props {
  onDone: () => void;
}

const ListingEditPage = ({ onDone }: Props) => {
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const { imagesCount, images, removeAllImages } = useImages(MAX_IMAGES);
  const { addListing } = useListings();
  const currentUser = useUser();

  useEffect(() => {
    return () => removeAllImages();
  }, []);

  const createListing = async (info: NewLisitingInfo) => {
    setLoading(true);
    const imagesURL = await storage.saveImages(images);
    const response = await service.addListing({
      ...info,
      images: imagesURL,
      description: info.description || "",
      category: selectedCategory?._id || "",
    });
    setLoading(false);

    if (!response.ok) await storage.deleteImages(imagesURL);

    return response;
  };

  const handleSubmit = async (info: NewLisitingInfo) => {
    if (!currentUser.user)
      return setError("Users need to know whose listing this is. Login");
    if (isLoading) return;
    if (error) setError("");
    if (!imagesCount) return setError("Please select at least one image");
    if (!selectedCategory) return setError("Please your listing category");

    const { data, ok, problem } = await createListing(info);
    if (!ok) return setError((data as DataError)?.error || problem);
    addListing(data as Listing);

    toast.success("Listing created successfully");
    removeAllImages();
    onDone();
  };

  if (!currentUser) return <Navigate to="/login" replace />;

  return (
    <div className="px-4 mb-5">
      <Form
        initialValues={{
          price: 1,
          title: "",
          description: "",
          phone: "",
        }}
        onSubmit={handleSubmit}
        title="New Listing"
        validationSchema={schema}
      >
        <ImageInputList imagesLimit={MAX_IMAGES} />
        <ErrorMessage error={error} visible />
        <FormField name="title" />
        <FormField name="phone" />
        <FormField name="price" type="number" />
        <FormField name="description" />
        <CategorySelect
          onCategorySelect={setSelectedCategory}
          selectedCategory={selectedCategory}
        />
        <SubmitButton title={isLoading ? "Creating" : "Create"} />
      </Form>
    </div>
  );
};

export default ListingEditPage;
