import { ListingInfo, NewListingInfo } from "../hooks/useListings";
import client, { getFailedResponse, processResponse } from "./client";

export const endpoint = "/listings";

const addListing = async (
  info: NewListingInfo,
  onUploadProgress: (progress: number) => void = () => {}
) => {
  try {
    return processResponse(
      await client.post(endpoint, info, {
        onUploadProgress: (progressEvent) => {
          if (
            progressEvent.total !== null &&
            progressEvent.total !== undefined
          ) {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            onUploadProgress(progress);
          }
        },
      })
    );
  } catch (error) {
    return getFailedResponse(error);
  }
};

const getListings = async () => {
  try {
    return processResponse(await client.get(endpoint));
  } catch (error) {
    return getFailedResponse(error);
  }
};

const updateListing = (
  listing: ListingInfo,
  onUploadProgress: (progress: number) => void = () => {}
) =>
  client.patch(`${endpoint}/${listing._id}`, listing, {
    onUploadProgress: (progressEvent) => {
      if (progressEvent.total !== null && progressEvent.total !== undefined) {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        onUploadProgress(progress);
      }
    },
  });

const deleteListing = async (listingId: string) => {
  try {
    return processResponse(await client.delete(`${endpoint}/${listingId}`));
  } catch (error) {
    return getFailedResponse(error);
  }
};

const getUserListings = (userId: string) => client.get(`${endpoint}/${userId}`);

const getListing = (listingId: string) =>
  client.get(`${endpoint}/${listingId}`);

const convertToShopProduct = (listingId: string, shopId: string) =>
  client.post(`${endpoint}/to-product/${listingId}`, { shop: shopId });

export default {
  addListing,
  convertToShopProduct,
  deleteListing,
  getListing,
  getListings,
  getUserListings,
  updateListing,
};
