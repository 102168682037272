import { useContext } from "react";
import { toast } from "react-toastify";

import { Category } from "./useCategories";
import { User } from "./useUser";
import ListingsContext from "../contexts/ListingsContext";
import service from "../services/listings";
import storage from "../db/image";
import { getFailedResponse, processResponse } from "../services/client";

export interface ListingBase {
  description: string;
  title: string;
}

export interface NewListingInfo extends ListingBase {
  images: string[];
  price: string | number;
  category?: string;
}

export interface ListingInfo extends ListingBase {
  _id: string | undefined;
  author: string | undefined;
  category: string | undefined;
  images?: string[];
  price: number | string;
}

interface Author extends User {}

export interface Listing extends ListingBase {
  _id: string;
  category: Category;
  author: Author;
  images: string[];
  timestamp: number;
  phone?: string;
  price: number;
}

const useListings = () => {
  const { listings, setListings } = useContext(ListingsContext);

  const updateListing = (listing: Listing) =>
    setListings(
      listings.map((l) => {
        if (l._id === listing._id) l = listing;

        return l;
      })
    );

  const deleteListing = async (listingId: string | undefined) => {
    if (!listingId) return;
    let found: Listing | undefined;
    const prevListings = [...listings];

    setListings(
      listings.filter((listing) => {
        if (listing._id === listingId) found = listing;

        return listing._id !== listingId;
      })
    );

    const { ok } = await service.deleteListing(listingId);
    if (ok) {
      if (found) await storage.deleteImages(found.images);
      return toast.done("Listing deleted successfully");
    }

    setListings(prevListings);
    toast.error("Listing deletion failed!");
  };

  const addListing = (listing: Listing) => setListings([listing, ...listings]);

  async function getListing(listingId: string | undefined) {
    if (!listingId) return getFailedResponse("invalid id");

    try {
      return processResponse(await service.getListing(listingId));
    } catch (error) {
      return getFailedResponse(error);
    }
  }

  return {
    addListing,
    deleteListing,
    getListing,
    listings,
    setListings,
    updateListing,
  };
};

export default useListings;
