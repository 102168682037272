import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Category } from "../hooks/useCategories";
import {
  ErrorMessage,
  Form,
  FormField,
  FormTextAreaField,
  SubmitButton,
} from "../components/form";
import { DataError } from "../services/client";
import { Request } from "../hooks/useRequests";
import { useImages, useRequests, useUser } from "../hooks";
import CategorySelect from "../components/listings/CategorySelect";
import ImageInputList from "../components/common/ImageInputList";
import service from "../services/requests";
import userService from "../services/users";
import storage from "../db/image";

const schema = Yup.object().shape({
  title: Yup.string().min(3).required(),
  description: Yup.string(),
  phone: Yup.string(),
});

type Info = Yup.InferType<typeof schema>;

const MAX_IMAGES = 1;

interface Props {
  onDone: () => void;
}

const RequestEditPage = ({ onDone }: Props) => {
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const { addRequest } = useRequests();
  const { imagesCount, images, removeAllImages } = useImages(MAX_IMAGES);
  const { user } = useUser();

  useEffect(() => {
    return () => removeAllImages();
  }, []);

  const handleSubmit = async (info: Info) => {
    if (!user) return setError("People need to know who's requesting. Login!");
    if (isLoading) return;
    if (error) setError("");
    if (!selectedCategory) return setError("Please your listing category");
    setLoading(true);

    if (info.phone)
      await userService.updateUserInfo({
        otherAccounts: { whatsapp: info.phone },
      });

    let image = "";
    if (imagesCount) image = await storage.saveImage(images[0]);

    try {
      const { title } = info;
      const res = await service.create({
        title,
        image,
        category: selectedCategory._id,
        description: info.description || "",
      });
      setLoading(false);

      if (!res.ok) {
        if (imagesCount) await storage.deleteImage(image);
        toast.error("Something failed!");
        return setError((res.data as DataError).error || res.problem);
      }
      toast.success("Your requeste is sent successfully");
      addRequest(res.data as Request);
      onDone();
    } catch (error) {
      setLoading(false);
      if (imagesCount) await storage.deleteImage(image);
      setError("Something unexpected happened! Try again later!");
    }
  };

  return (
    <div className="mb-5">
      <Form
        initialValues={{ title: "", description: "", phone: "" }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        <p className="text-sm">Image is optional</p>
        <ImageInputList imagesLimit={MAX_IMAGES} />
        <ErrorMessage error={error} visible />
        <FormField name="title" />
        <FormField
          name="phone"
          placeholder="optional if you already saved your phone"
        />
        <CategorySelect
          onCategorySelect={setSelectedCategory}
          selectedCategory={selectedCategory}
        />
        <FormTextAreaField name="description" />
        <SubmitButton title={isLoading ? "Creating" : "Create"} />
      </Form>
    </div>
  );
};

export default RequestEditPage;
