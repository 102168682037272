import { useNavigate } from "react-router-dom";

import { events, logEvent } from "../../db/analytics";
import { useCart } from "../../hooks";
import ShoppingCartIcon from "../ShoppingCartIcon";

const Cart = () => {
  const cart = useCart();
  const navigate = useNavigate();

  const cartCount = cart.count;

  const viewCart = () => {
    logEvent(events.eCommerce.VIEW_CART);
    navigate("/mart/cart");
  };

  if (!cartCount) return null;

  return (
    <div className="dropdown dropdown-end">
      <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
        <div className="indicator">
          <ShoppingCartIcon />
          <span className="badge badge-sm indicator-item">{cartCount}</span>
        </div>
      </div>
      <div
        tabIndex={0}
        className="mt-3 z-[1] card card-compact dropdown-content w-52 bg-base-100 shadow"
      >
        <div className="card-body">
          <span className="font-bold text-lg">{cartCount} Items</span>
          <span className="text-info">
            Subtotal: Ksh {cart.getCartGrandTotal()}
          </span>
          <div className="card-actions">
            <button className="btn btn-primary btn-block" onClick={viewCart}>
              View cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
