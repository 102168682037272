import { getTimeDiffInMinutesFromNow } from "./productTypes";
import { Product } from "../hooks/useProducts";
import apiClient, {
  getFailedResponse,
  processResponse,
  Response,
} from "./client";
import cache from "./cache";
import productsCache from "../cache/products";

export const endpoint = "/products";
const CACHE_TIME_IN_MINUTES = 20;

export interface NewProduct {
  author: string;
  description?: string | undefined;
  name: string;
  price: number;
  images: string[];
  shop: string;
  type: string;
}

type CacheProducts = { time: number; data: Product[] };

const create = async (product: NewProduct) => {
  try {
    return processResponse(await apiClient.post(endpoint, product));
  } catch (error) {
    return getFailedResponse(error);
  }
};

async function fetchProducts(url?: string) {
  return processResponse(await apiClient.get(url || endpoint));
}

export const getProducts = async (): Promise<Product[]> => {
  const { data, ok } = await fetchProducts();

  return ok ? (data as Product[]) : [];
  // try {
  //   const result = cache.get(endpoint) as CacheProducts | undefined;
  //   if (!result) {
  //     const { data, ok } = await fetchProducts();

  //     if (ok) {
  //       cache.store(endpoint, { time: Date.now(), data });

  //       return data as Product[];
  //     } else return [];
  //   }

  //   if (getTimeDiffInMinutesFromNow(result.time) < CACHE_TIME_IN_MINUTES)
  //     return result.data;

  //   const { data, ok } = await fetchProducts();
  //   return ok ? (data as Product[]) : result.data;
  // } catch (error) {
  //   return [];
  // }
};

export const getProductFromServer = (productId: string) =>
  apiClient.get(`${endpoint}/single/${productId}`);

export const getProduct = async (productId: string): Promise<Response> => {
  const targetEndpoint = `${endpoint}/single/${productId}`;

  try {
    const result = cache.get(endpoint) as CacheProducts | undefined;
    if (!result) return fetchProducts(targetEndpoint);

    if (getTimeDiffInMinutesFromNow(result.time) > CACHE_TIME_IN_MINUTES)
      return fetchProducts(targetEndpoint);
    else {
      const found = result.data.find((p) => p._id === productId);
      if (found) return { data: found, ok: true, problem: "" };
      return fetchProducts(targetEndpoint);
    }
  } catch (error) {
    return getFailedResponse(error);
  }
};

const getProductURL = (productId: string) => `${endpoint}/${productId}`;

const deleteProductBy = async (productId: string) => {
  try {
    return processResponse(await apiClient.delete(getProductURL(productId)));
  } catch (error) {
    return getFailedResponse(error);
  }
};

const update = async (info: object, productId: string) => {
  try {
    return processResponse(
      await apiClient.patch(getProductURL(productId), info)
    );
  } catch (error) {
    return getFailedResponse(error);
  }
};

const addView = async (productId: string) => {
  try {
    return processResponse(
      await apiClient.patch(`${endpoint}/views/${productId}`)
    );
  } catch (error) {
    return getFailedResponse(error);
  }
};

export default {
  addView,
  create,
  deleteProductBy,
  getProducts,
  getProduct,
  getProductFromServer,
  update,
};
