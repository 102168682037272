import { useEffect } from "react";

const useUrlQuery = () => {
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const url = params.get("q");

    if (url) window.location.href = url;
  }, []);

  return {};
};

export default useUrlQuery;
