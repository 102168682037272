import { useEffect, useState } from "react";
import {
  FlatFeed,
  LoadMorePaginator,
  useStreamContext,
} from "react-activity-feed";

import {
  CopyUrlButton,
  Grid,
  Input,
  ProductCard,
  ProductTypesList,
  ShopPageHeader,
  ShopStats,
} from "../components";
import { emptyShop, emptyType } from "../utils/empty";
import { FeedUser } from "../hooks/useFeedUser";
import { Product, ProductType } from "../hooks/useProducts";
import { Shop } from "../hooks/useShop";
import {
  useProducts,
  useProductTypes,
  useReload,
  useShops,
  useUrlQuery,
  useUser,
} from "../hooks";
import { logEvent } from "../db/analytics";
import ChatButtons from "../components/ChatButtons";
import LoadingIndicator from "../components/LoadingIndicator";
import LoadMoreButton from "../components/products/LoadMore";
import ProductActivity from "../components/products/Activity";
import service from "../services/shops";
import SubscribeButton from "../components/shop/SubscribeButton";
import { funcs } from "../utils";
import { useParams } from "react-router-dom";

const ShopPage = () => {
  const [selectedType, setSelectedType] = useState<ProductType>({
    _id: "",
    label: "",
  });
  const { types: allTypes } = useProductTypes();
  const [shopTypes, setShopTypes] = useState<ProductType[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const { products: allProducts } = useProducts();
  const [activitiesCount, setActivitiesCount] = useState(0);
  const [query, setQuery] = useState("");
  const helper = useShops();
  const { user } = useUser();
  const { client } = useStreamContext();
  const [feedUser, setFeedUser] = useState<FeedUser>();
  const { shopName } = useParams();
  const {
    info: shop,
    isLoading,
    request,
  } = useReload<Shop>(
    null,
    { ...emptyShop, paramsId: "shopName" },
    service.getShop,
    true
  );
  useUrlQuery();

  const { image, author, types } = shop;
  const userIsTheSeller = user?._id === author._id;
  const shopFeed = client?.feed("user", shop._id);

  useEffect(() => {
    function initShopProducts() {
      const shopProducts = allProducts.filter(
        (p) => funcs.convertShopNameToUrl(p.shop?.name) === shopName
      );

      setProducts(shopProducts);
    }

    initShopProducts();
  }, []);

  useEffect(() => {
    logEvent("shop_view", {
      userId: user?._id,
      shopId: shop._id,
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  useEffect(() => {
    async function getActivitiesCount() {
      try {
        const activities = await shopFeed?.get();

        if (activities) setActivitiesCount(activities.results.length);
      } catch (error) {
        console.log("err", error);
      }
    }

    // getActivitiesCount();

    if (!user?._id) return;

    const getUser = async () => {
      try {
        const feedUser = await client
          ?.user(user?._id)
          .get({ with_follow_counts: true });

        if (feedUser?.full) setFeedUser(feedUser.full);
      } catch (error) {
        console.error(`Error ${error}`);
      }
    };

    getUser();
  }, [user?._id, activitiesCount]);

  useEffect(() => {
    initData();
    helper.incShopViews(shop._id);
  }, [allTypes.length, types, activitiesCount]);

  const initData = () => {
    if (!shop._id && !isLoading) request();

    const result: ProductType[] = [{ _id: "", label: "All Types" }];
    allTypes.forEach((type) => {
      if (types[type._id]) result.push(type);
    });
    setShopTypes(result);
  };

  const filtered = selectedType?._id
    ? products.filter(({ shop, type }) =>
        type ? type._id === selectedType?._id : shop?.types?.[selectedType._id]
      )
    : products;

  const queried = query
    ? filtered.filter((product) =>
        product.name.toLowerCase().includes(query.toLowerCase())
      )
    : filtered;

  const handleQueryChange = (query: string) => {
    setSelectedType(emptyType);
    setQuery(query);
  };

  const handleTypeChange = (type: ProductType) => {
    setQuery("");
    setSelectedType(type);
  };

  return (
    <section>
      <ShopPageHeader shop={shop} />
      <article className="flex flex-col items-center justify-center my-4">
        <div className="max-w-xs text-center">
          {!userIsTheSeller && shop._id && (
            <SubscribeButton shopId={shop._id} />
          )}
          <div className="flex justify-center mt-2">
            <CopyUrlButton label="Shop" />
          </div>
        </div>
      </article>

      {!userIsTheSeller && (
        <article className="mb-4">
          <ChatButtons seller={author} />
        </article>
      )}

      <section className="flex flex-col items-center px-8 pb-6 w-full max-w-full overflow-x-hidden">
        <ShopStats
          productsCount={products.length}
          shopImage={image}
          subscribers={feedUser?.following_count || 0}
          seller={author}
        />

        <article className="mt-5 w-full max-w-full">
          <Input
            placeholder="Search products..."
            value={query}
            onChange={handleQueryChange}
          />
        </article>

        <ProductTypesList
          badges={shopTypes}
          onTypeSelect={handleTypeChange}
          selectedType={selectedType}
        />
      </section>

      <section className="px-6">
        {/* <Grid>
          <FlatFeed
            Activity={ProductActivity}
            feedGroup="user"
            notify
            userId={shop._id}
            LoadingIndicator={LoadingIndicator}
            Paginator={(props) => (
              <LoadMorePaginator
                {...props}
                LoadMoreButton={(props) => <LoadMoreButton {...props} />}
              />
            )}
          />
        </Grid> */}
        <Grid>
          {queried.map((p) => (
            <ProductCard {...p} key={p._id} />
          ))}
        </Grid>
      </section>
    </section>
  );
};

export default ShopPage;
