import { useState, useEffect, useCallback } from "react";
import * as use from "@tensorflow-models/universal-sentence-encoder";
import _ from "lodash";

import { paginate } from "../utils";
import {
  CardSkeletons,
  Modal,
  Pagination,
  ProductHeader,
  ProductsGrid,
  ProductTypesList,
} from "../components";
import { logEvent } from "../db/analytics";
import { useProductTypes, useUser } from "../hooks";
import queriesService from "../services/queries";
import useProduts, { Product, ProductType } from "../hooks/useProducts";
import RequestEditPage from "./RequestEditPage";

const ProductsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(16);
  const [isCreatingRequest, setCreatingRequest] = useState(false);
  const [query, setQuery] = useState("");
  const { isLoading, products } = useProduts();
  const { types } = useProductTypes();
  const { user } = useUser();
  const [selectedType, setSelectedType] = useState<ProductType>({
    _id: "",
    label: "",
  });
  const [model, setModel] = useState<any>(null);
  const [semanticQueriedProducts, setSemanticQueriedProducts] = useState<
    Product[]
  >([]);
  const [loadingQuery, setLoadingQuery] = useState(false);

  useEffect(() => {
    use.load().then((loadedModel) => setModel(loadedModel));
  }, []);

  const cosineSimilarity = (vecA: number[], vecB: number[]) => {
    const dotProduct = vecA.reduce((sum, a, idx) => sum + a * vecB[idx], 0);
    const magnitudeA = Math.sqrt(vecA.reduce((sum, a) => sum + a * a, 0));
    const magnitudeB = Math.sqrt(vecB.reduce((sum, b) => sum + b * b, 0));
    return dotProduct / (magnitudeA * magnitudeB);
  };

  const handleSemanticQuery = useCallback(
    async (query: string) => {
      if (!model || !query || !products.length) return;

      setLoadingQuery(true);

      const queryEmbedding = await model.embed([query]);
      const queryVector = queryEmbedding.arraySync()[0];

      const productNames = products.map((p) => p.name);
      const productEmbeddings = await model.embed(productNames);
      const productVectors = productEmbeddings.arraySync();

      // Calculate similarity for each product and sort by similarity score
      const similarProducts = products
        .map((product, index) => ({
          product,
          similarity: cosineSimilarity(queryVector, productVectors[index]),
        }))
        .sort((a, b) => b.similarity - a.similarity);

      // Filter by a similarity threshold, e.g., 0.5 for reasonable matches
      const threshold = 0.5;
      const filteredProducts = similarProducts
        .filter(({ similarity }) => similarity > threshold)
        .map(({ product }) => product);

      setSemanticQueriedProducts(filteredProducts);
      setLoadingQuery(false);
    },
    [model, products]
  );

  const filtered = selectedType?._id
    ? products.filter(({ shop, type }) =>
        type ? type._id === selectedType?._id : shop?.types?.[selectedType._id]
      )
    : products;

  const queried = query ? semanticQueriedProducts : filtered;

  const saveNotFoundQuery = useCallback(
    _.debounce(async (query: string) => {
      queriesService.create({
        text: query,
        userId: user?._id || "",
      });
    }, 1_000),
    [user]
  );

  useEffect(() => {
    if (!queried.length && query && !isLoading && !loadingQuery)
      saveNotFoundQuery(query);
  }, [queried, query, isLoading, loadingQuery, saveNotFoundQuery]);

  useEffect(() => {
    if (query) handleSemanticQuery(query);
  }, [query, handleSemanticQuery]);

  const paginated = paginate<Product>(queried, currentPage, pageSize);

  const handleQueryChange = (query: string) => {
    setSelectedType({ _id: "", label: "" });
    setQuery(query);
  };

  const handleTypeChange = (type: ProductType) => {
    setQuery("");
    setSelectedType(type);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scroll(0, 0);
    logEvent("products_page_change", { page });
  };

  return (
    <article>
      <ProductHeader
        placeholder="products"
        query={query}
        onQuery={handleQueryChange}
      />
      <ProductTypesList
        badges={types}
        onTypeSelect={handleTypeChange}
        selectedType={selectedType}
      />

      {loadingQuery && (
        <div className="text-center mt-8">
          <h2 className="text-xl font-semibold">
            "Hang tight! I'm hunting down some awesome products that I think
            you'll love. Almost there, promise!"
          </h2>
        </div>
      )}

      {!queried.length &&
        !isLoading &&
        !loadingQuery &&
        !query &&
        selectedType.label && (
          <h1 className="text-center mt-3">
            No {selectedType.label} products found.
          </h1>
        )}

      <Modal
        isOpen={isCreatingRequest}
        content={<RequestEditPage onDone={() => setCreatingRequest(false)} />}
        onClose={() => setCreatingRequest(false)}
        title="New Request"
      />

      <CardSkeletons
        isLoading={isLoading || loadingQuery}
        pageSize={pageSize}
      />

      <ProductsGrid products={paginated} />

      <Pagination
        currentPage={currentPage}
        itemsCount={queried.length}
        onPageChange={handlePageChange}
        pageSize={pageSize}
      />
    </article>
  );
};

export default ProductsPage;
