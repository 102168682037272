import { useContext } from "react";
import { Category } from "./useCategories";
import { User } from "./useUser";
import { RequestsContext } from "../contexts";

export interface Request {
  _id: string;
  author: User;
  category: Category;
  description: string;
  image?: string;
  title: string;
  timestamp: number;
}

export interface RequestInfo {
  category: string;
  title: string;
  description: string;
  image: string;
}

const useRequests = () => {
  const { requests, setRequests } = useContext(RequestsContext);

  const addRequest = (request: Request) => setRequests([request, ...requests]);

  return { addRequest, requests, setRequests };
};

export default useRequests;
