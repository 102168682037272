import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import LoadingIndicator from "../components/LoadingIndicator";
import ListingCard from "../components/listings/ListingCard";
import useListings, { Listing } from "../hooks/useListings";

const ListingDetailsPage = () => {
  const [error, setError] = useState("");
  const [listing, setListing] = useState<Listing>();
  const { listingId } = useParams();
  const helper = useListings();
  const navigate = useNavigate();

  useEffect(() => {
    if (!listingId) return navigate("/");
    initListing();
  }, []);

  async function initListing() {
    if (error) setError("");

    const res = await helper.getListing(listingId);

    res.ok
      ? setListing(res.data as Listing)
      : setError("Couldn't fetch listing");
  }

  if (error) return <p className="text-center px-3 text-red-600">{error}</p>;

  return (
    <LoadingIndicator isLoading={!listing}>
      <div className="justify-center items-center">
        {listing && <ListingCard {...listing} />}
      </div>
    </LoadingIndicator>
  );
};

export default ListingDetailsPage;
