import { BsCalendar2Check, BsChat, BsScissors } from "react-icons/bs";
import { CgShoppingBag } from "react-icons/cg";

import { useUnreadChats } from "../../hooks";
import BottomTab from "./BottomTab";
import CountBadge from "../CountBadge";

const BottomNav = () => {
  const { unreadCount } = useUnreadChats();

  return (
    <div className="btm-nav md:hidden">
      <BottomTab
        Icon={<CgShoppingBag size={24} />}
        label="Products"
        pathname="/mart"
      />

      <BottomTab
        Icon={<BsScissors size={20} />}
        label="Services"
        pathname="/services"
      />

      <BottomTab
        Icon={<BsCalendar2Check size={20} />}
        label="Posters"
        pathname="/events"
      />

      <BottomTab
        children={<CountBadge count={unreadCount} right={-10} top={-1} />}
        Icon={<BsChat size={20} />}
        label="Chats"
        pathname="/chats"
      />
    </div>
  );
};

export default BottomNav;
