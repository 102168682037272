import React from "react";

import { Badge } from "..";
import { Category } from "../../hooks/useCategories";
import { useCategories } from "../../hooks";

const Container = ({ children }: React.PropsWithChildren<{}>) => (
  <article className="flex overflow-x-auto whitespace-nowrap">
    {children}
  </article>
);

interface Props {
  onCategorySelect: (category: Category) => void;
  selectedCategory: Category | undefined;
}

const CategorySelect = ({ onCategorySelect, selectedCategory }: Props) => {
  const { categories } = useCategories();

  return (
    <Container>
      {categories
        .filter((c) => c._id)
        .map((category, index) => (
          <section key={index} className="mr-2">
            <Badge
              {...category}
              selectedType={selectedCategory}
              onClick={() => onCategorySelect(category)}
            />
          </section>
        ))}
    </Container>
  );
};

export default CategorySelect;
