import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import Input from "../Input";

interface Props {
  onQuery: (query: string) => void;
  placeholder: string;
  query: string;
}

const info = [
  "Payments are done in person.",
  "This is a legitimate start-up business.",
  "Order online, pay in person.",
  "Prices are negotiable",
  "Ask sellers for other related products",
  "Feel free to ask any other questions!",
];

const Header = ({ onQuery, query, placeholder }: Props) => {
  const [currentAnswerIndex, setCurrentAnswerIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAnswerIndex((prevIndex) => (prevIndex + 1) % info.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const viewDocs = () => {
    navigate("/mart/guides");
  };

  return (
    <section>
      <div style={{ width: "98.5%" }} className="mx-auto">
        <GuideButton
          className="btn btn-outline btn-primary mb-2 w-full"
          onClick={viewDocs}
        >
          {info[currentAnswerIndex]}
        </GuideButton>
      </div>

      <header className="max-w-100 mx-auto flex items-center space-x-4 pl-1 pr-3">
        <div className="relative flex-grow">
          <Input
            placeholder={`Search ${placeholder}...`}
            value={query}
            onChange={onQuery}
          />
          {query && (
            <button
              className="absolute inset-y-0 right-0 px-3 py-2 bg-transparent text-gray-500"
              onClick={() => onQuery("")}
            >
              X
            </button>
          )}
        </div>
      </header>
    </section>
  );
};

export default Header;

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const GuideButton = styled.button`
  animation: ${slideIn} 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
`;
