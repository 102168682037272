import { Gallery, UserBar } from "react-activity-feed";
import { FaWhatsapp } from "react-icons/fa";

import { funcs } from "../../utils";
import { getWhatsAppLink } from "../../hooks/useWhatsAppRedirect";
import { Listing } from "../../hooks/useListings";
import { logEvent } from "../../db/analytics";
import { useUser } from "../../hooks";
import logo from "../../assets/logo.png";

const ListingCard = ({
  _id,
  author,
  description,
  images,
  price,
  timestamp,
  title,
  phone,
}: Listing) => {
  const { user } = useUser();

  const handleWhatsAppDM = () => {
    const phoneNumber =
      phone || author.otherAccounts?.whatsapp || "+254745889801";

    logEvent("listing_whatsappDM", { listingId: _id, userId: user?._id });

    funcs.navTo(getWhatsAppLink(phoneNumber));
  };

  const isAdmin =
    author.name.includes("Augustine") || author.name.includes("Mathan");

  return (
    <div className="border rounded-md shadow-lg p-4 flex flex-col justify-between">
      <div>
        <UserBar
          username={isAdmin ? "Amazing" : author.name}
          avatar={isAdmin ? logo : author.avatar}
          timestamp={timestamp}
        />
        <h2 className="font-bold text-lg">{title}</h2>
        <p className="text-gray-600">{description}</p>

        {images && images.length > 0 && (
          <div className="mt-2">
            <Gallery
              images={images}
              style={{ border: "none", borderRadius: 17 }}
            />
          </div>
        )}
      </div>

      <p className="mt-2 font-semibold text-center">
        Ksh {funcs.addComma(price)}
      </p>
      <div className="mt-4 flex justify-end">
        <button
          onClick={handleWhatsAppDM}
          className="flex items-center bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition w-full"
        >
          <FaWhatsapp className="mr-2" size={20} /> Contact via WhatsApp
        </button>
      </div>
    </div>
  );
};

export default ListingCard;
