import { Avatar, Gallery } from "react-activity-feed";

import { getWhatsAppLink } from "../../hooks/useWhatsAppRedirect";
import { Request } from "../../hooks/useRequests";
import { useTimestamp, useUser } from "../../hooks";
import { funcs } from "../../utils";

const RequestCard = ({
  author,
  title,
  category,
  timestamp,
  image,
}: Request) => {
  const { tempTimestamp } = useTimestamp(timestamp);
  const { user } = useUser();

  const handleWhatsAppDM = () => {
    const url = getWhatsAppLink(
      user?.otherAccounts?.whatsapp || "+254745889801"
    );
    funcs.navTo(url);
  };

  return (
    <div>
      <div
        className={`chat chat-${author._id === user?._id ? "end" : "start"}`}
      >
        <div className="chat-image avatar">
          <div className="w-10 rounded-full">
            {author.avatar ? (
              <img alt="profile" src={author.avatar} />
            ) : (
              <Avatar />
            )}
          </div>
        </div>
        <div className="chat-header">
          <time className="text-xs opacity-50">{tempTimestamp}</time>
        </div>
        <div className="chat-bubble">
          {image && <Gallery images={[image]} className="rounded-sm" />}
          {title}
        </div>
        <div
          className="chat-footer flex items-center opacity-50"
          onClick={handleWhatsAppDM}
        >
          {category.label}
          <p className="text-green-500 font-bold cursor-pointer ml-2">
            WhatsApp me
          </p>
        </div>
      </div>
    </div>
  );
};

export default RequestCard;
