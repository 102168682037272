import { useState } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { Modal, RegisterForm, LoginForm } from "..";
import { signOut } from "firebase/auth";
import { useShowNav, useUnreadChats } from "../../hooks";
import auth, { googleAuth } from "../../services/auth";
import Cart from "./Cart";
import Drawer from "../common/Drawer";
import DrawerContent from "../drawer/DrawerContent";
import logo from "../../assets/logo.png";
import Notification from "./Notification";
import Tabs from "./Tabs";
import UserButton from "./UserButton";
import Accounts from "../accountSwitch/Accounts";

const NavBar = () => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showDrawerContent, setShowDrawerContent] = useState(false);
  const [showAccounts, setShowAccounts] = useState(false);
  const { unreadCount } = useUnreadChats();
  const { showNav } = useShowNav();
  const navigate = useNavigate();

  if (!showNav) return null;

  const switchToSignUp = () => {
    setShowLoginForm(false);
    setShowRegisterForm(true);
  };

  const switchToSignIn = () => {
    setShowLoginForm(true);
    setShowRegisterForm(false);
  };

  const handleUserLogout = () => {
    signOut(googleAuth);
    auth.logout();
    window.location.reload();
  };

  return (
    <>
      <Modal
        content={<LoginForm onSignUpRequest={switchToSignUp} />}
        isOpen={showLoginForm}
        onClose={() => setShowLoginForm(false)}
        title=""
      />
      <Modal
        content={<RegisterForm onLoginRequest={switchToSignIn} />}
        isOpen={showRegisterForm}
        onClose={() => setShowRegisterForm(false)}
        title=""
      />
      <Modal
        title="Confirm Logout"
        content="Are you sure you want to log out? We’ll miss you!"
        isOpen={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        onPrimaryBtnClick={handleUserLogout}
        onSecondaryBtnClick={() => setShowLogoutModal(false)}
        primaryBtnLabel="Yes, Log Me Out"
        secondaryBtnLabel="No, Keep Me Logged In"
      />
      <Modal
        content={
          <Accounts onDoneAccountSwitch={() => setShowAccounts(false)} />
        }
        isOpen={showAccounts}
        onClose={() => setShowAccounts(false)}
        title="Switch Account"
      />
      <Drawer
        children={
          <DrawerContent
            onSwitchAccounts={() => setShowAccounts(true)}
            onContentClick={() => setShowDrawerContent(false)}
          />
        }
        isOpen={showDrawerContent}
        toggleDrawer={() => setShowDrawerContent(false)}
      />

      <nav className="flex justify-between items-center navbar bg-base-100 w-full fixed top-0 left-0 right-0 border-b border-gray-100 z-10 glass py-0">
        <HiMenuAlt1
          size={25}
          cursor="pointer"
          onClick={() => setShowDrawerContent(true)}
        />
        <img
          onClick={() => navigate("/")}
          src={logo}
          alt="App logo"
          className="w-8 absolute left-1/2 transform -translate-x-1/2 md:hidden cursor-pointer"
        />
        <div className="flex-1 flex justify-center md:justify-end">
          <Tabs count={unreadCount} />
        </div>
        <article className="flex-none flex items-center">
          <Notification />
          <Cart />
          <UserButton
            onLoginWithForms={setShowLoginForm}
            onLogOut={() => setShowLogoutModal(true)}
            onSwitchAccounts={() => setShowAccounts(true)}
          />
        </article>
      </nav>
    </>
  );
};

export default NavBar;
