import { useEffect, useState } from "react";
import { BsPlus, BsX } from "react-icons/bs";

import { Category } from "../hooks/useCategories";
import { Input, ProductTypesList } from "../components";
import { useCategories } from "../hooks";
import queryService, { Query } from "../services/queries";
import RequestCard from "../components/requests/RequestCard";
import RequestEditPage from "./RequestEditPage";
import useRequests from "../hooks/useRequests";

const RequestsPage = () => {
  const { requests } = useRequests();
  const [queries, setQueries] = useState<Query[]>([]);
  const [showRequests, setShowRequests] = useState(true); // Toggle state
  const [isCreatingRequest, setCreatingRequest] = useState(false);
  const [query, setQuery] = useState("");
  const { categories } = useCategories();
  const [selectedCategory, setSelectedCategory] = useState<Category>({
    _id: "",
    label: "",
  });

  useEffect(() => {
    const fetchQueries = async (): Promise<Query[]> => {
      let result: Query[] = [];

      try {
        const res = await queryService.getAll();

        result = res.ok ? (res.data as Query[]) : [];
      } catch (error) {}

      return result;
    };

    const initQueries = async () => setQueries(await fetchQueries());

    initQueries();
  }, []);

  const handleQuery = (query: string) => {
    setSelectedCategory({ _id: "", label: "" });
    setQuery(query);
  };

  const handleCategorySelect = (category: Category) => {
    setSelectedCategory(category);
    setQuery("");
  };

  const handleShowRequests = () => {
    setShowRequests(true);
    setQuery("");
  };

  const handleShowQueries = () => {
    setShowRequests(false);
    setQuery("");
  };

  return (
    <div>
      <div className="flex justify-center p-4">
        <div className="flex items-center bg-gray-200 p-1 rounded-full">
          <div
            className={`px-4 py-2 rounded-full cursor-pointer transition-all ${
              showRequests ? "bg-white shadow-md" : ""
            }`}
            onClick={handleShowRequests}
          >
            Requests
          </div>
          <div
            className={`px-4 py-2 rounded-full cursor-pointer transition-all ${
              !showRequests ? "bg-white shadow-md" : ""
            }`}
            onClick={handleShowQueries}
          >
            Queries
          </div>
        </div>
      </div>

      {showRequests ? (
        <div className="p-4 max-w-xl mx-auto">
          <section className="flex flex-col md:flex-row justify-center items-center gap-4 mt-4 mb-2 px-3">
            <p className="text-center">
              {isCreatingRequest
                ? "Not now?"
                : "Are you looking for something to buy but can't find it? Request it!"}
            </p>
            <button
              className="btn btn-secondary btn-sm mt-2 md:mt-0"
              onClick={() => setCreatingRequest(!isCreatingRequest)}
            >
              {isCreatingRequest ? <BsX /> : <BsPlus />}{" "}
              {isCreatingRequest ? "Cancel" : "Add"} Request
            </button>
          </section>

          {isCreatingRequest && (
            <RequestEditPage onDone={() => setCreatingRequest(false)} />
          )}

          <Input
            placeholder={`Search requests...`}
            value={query}
            onChange={handleQuery}
          />

          <ProductTypesList
            badges={categories}
            onTypeSelect={handleCategorySelect}
            selectedType={selectedCategory}
          />

          {Boolean(requests.length) ? (
            requests.map((request) => (
              <RequestCard key={request._id} {...request} />
            ))
          ) : (
            <div className="text-center text-gray-500 mt-4">
              No requests yet. Be the first to request a product!
            </div>
          )}
        </div>
      ) : (
        <div className="p-4 max-w-xl mx-auto">
          {/* Explanation for Queries */}
          <p className="text-center text-gray-400 mb-4">
            These are searches that customers are looking for. Explore them to
            see if you have something to offer!
          </p>

          <Input
            placeholder={`Search queries...`}
            value={query}
            onChange={handleQuery}
          />

          {Boolean(queries.length) ? (
            queries
              .filter((q) => q.text.toLowerCase().includes(query.toLowerCase()))
              .map((query) => (
                <div key={query._id} className="chat chat-start my-2">
                  <div className="chat-bubble">
                    <p>{query.text}</p>
                    <small className="text-gray-500">
                      {new Date(query.timestamp).toLocaleString()}
                    </small>
                  </div>
                </div>
              ))
          ) : (
            <div className="text-center text-gray-500 mt-4">
              No queries yet. Be the first to ask something!
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RequestsPage;
