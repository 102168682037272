import { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { sendMail } from "../services/mail";
import { useUser } from "../hooks";
import Input from "./Input";
import service, { ADMIN_EMAIL } from "../services/opinions";

const FeedbackInput = () => {
  const [showInput, setShowInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState("");
  const { user } = useUser();

  const handleLabelClick = () => setShowInput(!showInput);

  const handleSubmit = async () => {
    setLoading(true);
    const res = await service.save({ comment: feedback });
    setLoading(false);

    if (res.ok) {
      setShowInput(false);
      toast.success("Feedback sent successfully!");
      await sendMail({
        intro: `${feedback} by ${user?.name} of ID: ${user?._id}`,
        name: "Admin",
        subject: "New Feedback",
        to: ADMIN_EMAIL,
      });
      setFeedback("");
    } else toast.error("Something failed! Try again later");
  };

  return (
    <Container>
      <div className="feedback-container">
        <label className="feedback-label text-md" onClick={handleLabelClick}>
          What else do you wanna sell here?
        </label>
        {showInput && (
          <div className="feedback-input-section">
            <Input
              onChange={setFeedback}
              placeholder="What do you want to see next?"
              value={feedback}
            />

            <button onClick={handleSubmit} className="feedback-submit-btn mt-3">
              {loading ? "Submiting..." : "Submit"}
            </button>
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .feedback-container {
    text-align: center;

    .feedback-label {
      color: #333;
      cursor: pointer;
      margin-bottom: 10px;
    }

    .feedback-input-section {
      margin-top: 10px;
    }

    .feedback-input {
      padding: 10px;
      font-size: 1rem;
      width: 300px;
      margin-right: 10px;
    }

    .feedback-submit-btn {
      padding: 10px 20px;
      font-size: 1rem;
      cursor: pointer;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 5px;
    }
  }
`;

export default FeedbackInput;
