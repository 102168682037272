import { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { Avatar } from "stream-chat-react";
import { User } from "../hooks/useUser";
import { useUsers } from "../hooks";
import usersService from "../services/users";
import { CgSpinner } from "react-icons/cg";

const ContactUsPage = () => {
  const [admins, setAdmins] = useState<User[]>([]);
  const { users } = useUsers();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAdmins = async (): Promise<User[]> => {
      if (users.length) return users.filter((user) => user.isAdmin);

      const res = await usersService.getAdmins();

      return res.ok ? (res.data as User[]) : [];
    };

    const initAdmins = async () => {
      setLoading(true);
      setAdmins(await getAdmins());
      setLoading(false);
    };

    initAdmins();
  }, [users]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
        <CgSpinner className="animate-spin text-gray-500 text-6xl" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-3xl bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-semibold text-center text-gray-800 mb-6">
          Contact Us
        </h1>
        <p className="text-center text-gray-600 mb-8">
          We’re here to help. Whether you're a seller or a buyer, feel free to
          reach out to us.
        </p>
        <div className="grid gap-6 grid-cols-1 md:grid-cols-2">
          {admins.map((user, index) => (
            <div
              key={index}
              className="border border-gray-200 rounded-lg p-4 hover:shadow-lg transition-shadow duration-300 flex items-center space-x-4"
            >
              <Avatar image={user.avatar} name={user.name} size={50} />
              <div className="flex-grow">
                <h2 className="text-xl font-bold text-gray-700">{user.name}</h2>
                <p className="text-gray-600 mb-4">{user.email}</p>
                <a
                  href={`https://wa.me/${user?.otherAccounts?.whatsapp?.replace(
                    /\D/g,
                    ""
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center w-full px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 transition-colors duration-300"
                >
                  <FaWhatsapp className="mr-2" />
                  Contact on WhatsApp
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
