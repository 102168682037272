import React from "react";

import { useProfileUser } from "../hooks";
import styled from "styled-components";
import ProfileHeader from "../components/profile/ProfileHeader";
import ProfileBio from "../components/profile/ProfileBio";
import TabList from "../components/TabList";
import ProfileProducts from "../components/profile/ProfileProducts";
import ProfileShops from "../components/profile/ProfileShops";

const ProfilePage: React.FC = () => {
  const { profileUser } = useProfileUser();

  const tabs = [
    { label: "Shops", Page: <ProfileShops /> },
    { label: "Products", Page: <ProfileProducts /> },
  ];

  return (
    <Container>
      <ProfileHeader user={profileUser} />
      <main>
        <ProfileBio user={profileUser} />
      </main>
      <div className="tab-list">
        <TabList tabs={tabs} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  --profile-image-size: 100px;
`;

export default ProfilePage;
