import { useState } from "react";
import { BsPlus, BsX } from "react-icons/bs";

import { Category } from "../hooks/useCategories";
import { Input, ProductTypesList } from "../components";
import { useCategories, useListings } from "../hooks";
import ListingEditPage from "./ListingEditPage";
import ListingCard from "../components/listings/ListingCard";

const ListingsPage = () => {
  const { categories } = useCategories();
  const [isCreatingListing, setCreatingListing] = useState(false);
  const [query, setQuery] = useState("");
  const { listings } = useListings();
  const [selectedCategory, setSelectedCategory] = useState<Category>({
    _id: "",
    label: "",
  });

  const handleQuery = (query: string) => {
    setSelectedCategory({ _id: "", label: "" });
    setQuery(query);
  };

  const handleCategorySelect = (category: Category) => {
    setSelectedCategory(category);
    setQuery("");
  };

  const filtered = selectedCategory._id
    ? listings.filter((l) => l.category._id === selectedCategory?._id)
    : listings;

  const queried = query
    ? filtered.filter(({ description, title }) => {
        const simpleQuery = query.toLowerCase();

        return (
          title.toLowerCase().includes(simpleQuery) ||
          description.toLowerCase().includes(simpleQuery)
        );
      })
    : filtered;

  return (
    <div>
      <section className="flex flex-col md:flex-row justify-center items-center gap-4 mt-4 mb-2 px-3">
        <p className="text-center">
          {isCreatingListing
            ? "Not now?"
            : "Do you have any second hand items to sell?"}
        </p>
        <button
          className="btn btn-secondary btn-sm mt-2 md:mt-0"
          onClick={() => setCreatingListing(!isCreatingListing)}
        >
          {isCreatingListing ? <BsX /> : <BsPlus />}{" "}
          {isCreatingListing ? "Cancel" : "Add"} Listing
        </button>
      </section>

      {isCreatingListing && (
        <ListingEditPage onDone={() => setCreatingListing(false)} />
      )}

      <Input
        placeholder={`Search listings...`}
        value={query}
        onChange={handleQuery}
      />

      <ProductTypesList
        badges={categories}
        onTypeSelect={handleCategorySelect}
        selectedType={selectedCategory}
      />

      {Boolean(queried.length) ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
          {queried.map((listing) => (
            <ListingCard key={listing._id} {...listing} />
          ))}
        </div>
      ) : (
        <p className="text-center">
          Can't find it? Leave a request for someone to post it for you?
        </p>
      )}
    </div>
  );
};

export default ListingsPage;
