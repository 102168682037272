import { useEffect, useState } from "react";

import service from "../services/categories";

export interface Category {
  _id: string;
  label: string;
}

const useCategories = () => {
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    if (!categories.length) init();
  }, []);

  async function init() {
    const res = await service.getAll();
    if (res.ok) setCategories(res.data as Category[]);
  }

  return {
    categories: [{ _id: "", label: "All" }, ...categories],
  };
};

export default useCategories;
