import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import {
  AdminPage,
  AuthPage,
  ChatsPage,
  ContactUsPage,
  EventsPage,
  HomePage,
  ListingDetailsPage,
  MartGuidePage,
  NewProductPage,
  NotFoundPage,
  NotificationsPage,
  ProductActivityPage,
  ProductDetailsPage,
  ProfileOrderPage,
  ProfileOrdersPage,
  ProfilePage,
  ServicesPage,
  ShopEditPage,
  ShopOrderPage,
  ShopOrdersPage,
  ShopPage,
  ShoppingCartPage,
  ShopsPage,
} from "../pages";
import {
  ImagesContext,
  OrderContext,
  PostersContext,
  ProductContext,
  ProfileUserContext,
  RequestsContext,
  ShopsContext,
  UsersContext,
} from "../contexts";
import { Listing } from "../hooks/useListings";
import { Order } from "../hooks/useOrder";
import { Poster } from "../hooks/usePosters";
import { Product } from "../hooks/useProducts";
import { Request } from "../hooks/useRequests";
import { Shop } from "../hooks/useShop";
import { useData } from "../hooks";
import { User } from "../hooks/useUser";
import accounts from "../services/accounts";
import ListingsContext from "../contexts/ListingsContext";
import listingsService from "../services/listings";
import RedirectRoot from "../navigation/RedirectRoot";
import requestsService from "../services/requests";
import shopService from "../services/shops";

const AppRoutes = () => {
  const [images, setImages] = useState<File[]>([]);
  const [listings, setListings] = useState<Listing[]>([]);
  const [order, setOrder] = useState<Order>();
  const [posters, setPosters] = useState<Poster[]>([]);
  const [profileUser, setProfileUser] = useState<User>();
  const [requests, setRequests] = useState<Request[]>([]);
  const [shops, setShops] = useState<Shop[]>([]);
  const [shopsLoading, setShopsLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const { data: usersData } = useData<User>("/users");
  const [product, setProduct] = useState<Product>();

  useEffect(() => {
    initRequests();
    initListings();
    initShops();
    setUsers(usersData);
  }, []);

  async function initRequests() {
    setRequests(await getRequests());
  }

  async function getRequests(): Promise<Request[]> {
    const res = await requestsService.get();
    return res.ok ? (res.data as Request[]) : [];
  }

  async function initListings() {
    setListings(await getListings());
  }

  async function getListings(): Promise<Listing[]> {
    const res = await listingsService.getListings();

    return res.ok ? (res.data as Listing[]) : [];
  }

  async function initShops() {
    setShopsLoading(true);
    const res = await shopService.getShops();
    if (res.ok) {
      setShops(res.data as Shop[]);
      accounts.convertShopsToShopsAccountsAndSave(res.data as Shop[]);
    }
    setShopsLoading(false);
  }

  return (
    <ShopsContext.Provider value={{ shops, setShops, isLoading: shopsLoading }}>
      <ImagesContext.Provider value={{ images, setImages }}>
        <UsersContext.Provider value={{ users, setUsers }}>
          <ProfileUserContext.Provider value={{ profileUser, setProfileUser }}>
            <OrderContext.Provider value={{ order, setOrder }}>
              <PostersContext.Provider value={{ posters, setPosters }}>
                <ListingsContext.Provider value={{ listings, setListings }}>
                  <RequestsContext.Provider value={{ requests, setRequests }}>
                    <ProductContext.Provider value={{ product, setProduct }}>
                      <Routes>
                        <Route path="/admin" element={<AdminPage />} />
                        <Route path="/auth" element={<AuthPage />} />
                        <Route path="/chats" element={<ChatsPage />} />
                        <Route path="/events" element={<EventsPage />} />
                        <Route path="/services" element={<ServicesPage />} />
                        <Route
                          path="/notifications"
                          element={<NotificationsPage />}
                        />
                        <Route
                          path="/mart/listings/:listingId"
                          element={<ListingDetailsPage />}
                        />
                        <Route
                          path="/mart/guides"
                          element={<MartGuidePage />}
                        />
                        <Route
                          path="/mart/guides/contact"
                          element={<ContactUsPage />}
                        />
                        <Route
                          path="/mart/guides/:guide"
                          element={<MartGuidePage />}
                        />
                        <Route
                          path="/mart/cart"
                          element={<ShoppingCartPage />}
                        />
                        <Route
                          path="/mart/profile/:userId"
                          element={<ProfilePage />}
                        />
                        <Route
                          path="/mart/profile/:userId/orders"
                          element={<ProfileOrdersPage />}
                        />
                        <Route
                          path="/mart/profile/:userId/orders/:orderId"
                          element={<ProfileOrderPage />}
                        />
                        <Route
                          path="/mart/products/:productId/activity"
                          element={<ProductActivityPage />}
                        />
                        <Route
                          path="/mart/products/:productId"
                          element={<ProductDetailsPage />}
                        />
                        <Route
                          path="/mart/products/new"
                          element={<NewProductPage />}
                        />
                        <Route
                          path="/mart/shops/:shopName"
                          element={<ShopPage />}
                        />
                        <Route
                          path="/mart/shops/:shopName/orders"
                          element={<ShopOrdersPage />}
                        />
                        <Route
                          path="/mart/shops/:shopName/orders/:orderId"
                          element={<ShopOrderPage />}
                        />
                        <Route
                          path="/mart/shops/new"
                          element={<ShopEditPage />}
                        />
                        <Route path="/mart/shops" element={<ShopsPage />} />
                        <Route path="/mart" element={<HomePage />} />
                        <Route index element={<RedirectRoot />} />
                        <Route path="*" element={<NotFoundPage />} />
                      </Routes>
                    </ProductContext.Provider>
                  </RequestsContext.Provider>
                </ListingsContext.Provider>
              </PostersContext.Provider>
            </OrderContext.Provider>
          </ProfileUserContext.Provider>
        </UsersContext.Provider>
      </ImagesContext.Provider>
    </ShopsContext.Provider>
  );
};

export default AppRoutes;
