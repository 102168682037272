import client, { getFailedResponse, processResponse } from "./client";

const endpoint = "/opinions";
export const ADMIN_EMAIL = "augustineawuori95@gmail.com";

type Opinion = { comment: string };

const save = async (opinion: Opinion) => {
  try {
    return processResponse(await client.post(endpoint, opinion));
  } catch (error) {
    return getFailedResponse(error);
  }
};

export default { save };
