import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";

import { DataError, processResponse, Response } from "../services/client";
import { NewOrder, Order, OrderProducts } from "./useOrder";
import { OrderShopProducts } from "./useShop";
import { Product } from "./useProducts";
import { sendMail } from "../services/mail";
import { sendSMS } from "../services/sms";
import { useCart, useNotifications, useStatus, useUser } from ".";
import service from "../services/orders";
import { logEvent } from "../db/analytics";

const PENDING_ORDER_STATUS_ID = "65f7f5babfb2e60edd3733a1";

const useOrders = () => {
  const [success, setSuccess] = useState(true);
  const { createNotification } = useNotifications();
  const { status } = useStatus();
  const { user } = useUser();
  const cart = useCart();
  const navigate = useNavigate();

  const getShopsProducts = (): OrderShopProducts => {
    const shopsProducts: OrderShopProducts = {};

    cart.getProducts().forEach((p) => {
      const shopId = p.shop._id;

      if (shopsProducts[shopId])
        shopsProducts[shopId] = [...shopsProducts[shopId], p];
      else shopsProducts[shopId] = [p];
    });

    return shopsProducts;
  };

  const getPendingOrderStatusId = () =>
    status.find((s) => s.label.toLowerCase().includes("pending"))?._id ||
    PENDING_ORDER_STATUS_ID;

  const prepOrderProducts = (products: Product[]): OrderProducts => {
    const result: OrderProducts = {};

    products.forEach(({ _id }) => {
      result[_id] = cart.getProductQuantity(_id);
    });

    return result;
  };

  const prepOrder = (products: Product[], message: string): NewOrder => ({
    message,
    products: prepOrderProducts(products),
    shop: products[0].shop._id,
    status: getPendingOrderStatusId(),
  });

  const isOrderStateValid = (products: Product[]): boolean => {
    if (products.length) return true;

    const message = !products.length
      ? "Error! Your products are not reflected in your shopping bag"
      : "App error!";
    toast.error(message);

    navigate(-1);
    return false;
  };

  const process = (res: AxiosResponse<unknown, unknown>): Response => {
    const { data, ok, problem } = processResponse(res);

    ok ? toast.success("Order placed successfully!") : toast.error(problem);

    return { data, ok, problem };
  };

  const makeOrder = async (products: Product[], message = "") => {
    if (!isOrderStateValid(products))
      return { data: null, ok: false, problem: "CLIENT_ERROR" };

    return process(await service.makeOrder(prepOrder(products, message)));
  };

  const makeShopOrder = async (prods: Product[], message: string) => {
    const { ok, data } = await makeOrder(prods, message);

    if (ok) {
      prods.forEach((p) => cart.remove(p._id));
      const { _id, products } = data as Order;

      createNotification(prods[0].shop._id, "order", {
        orderId: _id,
        products,
        message,
      });
    } else setSuccess(ok);
  };

  function getOrderMessage(message?: string) {
    return `AMAZING SHOP! You've received an order from ${
      user?.name || "Unknown user"
    } of phone number ${
      user?.otherAccounts?.whatsapp || "Not provided"
    }. ${message} Login and view your shop orders for more details`;
  }

  const makeShopsOrders = async (message: string) => {
    for (const [, products] of Object.entries(getShopsProducts())) {
      await makeShopOrder(products, message);
      await sendSMS({
        text: getOrderMessage(message),
        to: products[0].author?.otherAccounts?.whatsapp || "+254745889801",
      });
      await sendMail({
        intro:
          "Exciting news! A new order has just arrived for your Amazing shop! Log in to the shop page and navigate to orders page to view it",
        name: user?.name || "",
        subject: "🎉 You've Got a New Order on Amazing E-commerce!",
        to: user?.email || "",
      });
    }

    if (success) {
      cart.clear();
      navigate("/");
      logEvent("place_order", {
        userId: user?._id,
      });
    } else {
      logEvent("place_order_error", {
        userId: user?._id,
      });
      toast.error("Something went wrong! Some orders aren't placed");
    }

    return success;
  };

  const updateOrder = async (orderId: string, update: object) => {
    toast.loading("Updating order status...");
    const res = await service.updateOrder(orderId, update);
    toast.dismiss();

    const processedResponse = processResponse(res);

    processedResponse.ok
      ? toast.success("Order status updated successfully!")
      : toast.error(
          (res.data as DataError).error || "Order status update failed"
        );

    return processedResponse;
  };

  return { makeShopsOrders, updateOrder };
};

export default useOrders;
