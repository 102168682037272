import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsCartCheckFill, BsTruck } from "react-icons/bs";
import { FaEdit, FaHandshake, FaTrash } from "react-icons/fa";
import { HiHome, HiUser } from "react-icons/hi";

import { emptyProduct } from "../utils/empty";
import { funcs } from "../utils";
import {
  CopyUrlButton,
  HorizontalProductList,
  Modal,
  ProductUpdateForm,
  ShoppingCartIcon,
  Slider,
} from "../components";
import { useProduct, useReload, useUser } from "../hooks";
import ChatButtons from "../components/ChatButtons";
import LoadingIndicator from "../components/LoadingIndicator";
import SellerWhatsAppNumberInput from "../components/SellerWhatsAppNumberInput";
import service from "../services/products";
import useCart from "../hooks/useCart";
import useProducts, { Product } from "../hooks/useProducts";

const ProductDetailsPage = () => {
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isEditingProduct, setIsEditingProduct] = useState(false);
  const [loading, setLoading] = useState(false);
  const { product: savedProduct } = useProduct();
  const { productId } = useParams();
  const { user } = useUser();
  const cart = useCart();
  const helper = useProducts();
  const navigate = useNavigate();
  const { info, request } = useReload<Product>(
    null,
    { ...emptyProduct, paramsId: "productId" },
    service.getProductFromServer
  );

  const product = savedProduct || info;
  const productInCart = cart.hasProduct(productId || "");
  const currentUserIsTheSeller = user?._id === product?.shop?.author;

  useEffect(() => {
    const prepareProducts = async () => {
      if (!savedProduct) request();
    };

    setLoading(true);
    prepareProducts();
    setLoading(false);
    funcs.scrollToTop();
  }, [productId, product?._id, currentImageIndex, product?.shop?._id]);

  const updateCart = () =>
    productInCart ? cart.remove(productId || "") : cart.add(productId || "");

  const deleteProduct = async () => {
    if (!productId) return;
    const { ok } = await helper.deleteProductById(productId);
    if (ok) navigate("/");
  };

  const handleImageClick = (index: number) => setCurrentImageIndex(index);

  const navigateToShop = () =>
    navigate(`/mart/shops/${funcs.convertShopNameToUrl(product?.shop?.name)}`);

  const filteredProducts = (filterFn: (p: Product) => boolean) =>
    helper.products.filter(filterFn).filter(excludeTheDisplayed);

  const excludeTheDisplayed = (p: Product) => p._id !== productId;

  const ofTheSameType = (p: Product) => p.type?._id === product?.type?._id;

  const excludeTheOnesWithSimilarName = (p: Product) =>
    p.name.toLowerCase() !== product?.name?.toLowerCase();

  const relatedProducts = filteredProducts(
    (p) =>
      p.type?._id === product?.type?._id &&
      p.name.toLowerCase().includes(product?.name?.toLowerCase())
  );

  const samePriceProducts = filteredProducts(
    (p) => ofTheSameType(p) && excludeTheOnesWithSimilarName(p)
  );

  return (
    <LoadingIndicator isLoading={loading || !product?._id}>
      <section className="p-4">
        <Modal
          content="Are you sure you want to delete this product permanently?"
          isOpen={confirmDeletion}
          onClose={() => setConfirmDeletion(false)}
          title="Product Deletion"
          primaryBtnLabel="Yes, Delete"
          secondaryBtnLabel="No, Cancel"
          onPrimaryBtnClick={deleteProduct}
        />
        <Modal
          content={
            <ProductUpdateForm
              {...product}
              onDone={() => setIsEditingProduct(false)}
            />
          }
          isOpen={isEditingProduct}
          onClose={() => setIsEditingProduct(false)}
          title="Product Editing"
        />

        <article className="container mx-auto">
          <section className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Slider
              images={product?.images}
              initialIndex={currentImageIndex}
              onIndexChange={setCurrentImageIndex}
            />

            <article className="col-span-1">
              <div className="mr-3 flex mb-2">
                {product.images.length > 1 &&
                  product.images.map((image, index) => (
                    <img
                      key={image}
                      src={image}
                      alt={`Product Image ${index + 1}`}
                      onClick={() => handleImageClick(index)}
                      className={`w-20 h-20 rounded-md mr-3 object-cover cursor-pointer ${
                        index === currentImageIndex
                          ? "border-blue-500 border-2"
                          : ""
                      }`}
                    />
                  ))}
              </div>

              <div className="flex items-center">
                <p className="text-2xl font-bold text-white-800 mb-2">
                  {product?.name}
                </p>
                <button className="btn btn-ghost btn-xs btn-outline btn-accent ml-2">
                  {product?.type?.label}
                </button>
              </div>

              <p>{product?.description}</p>

              {product?.isNegotiable && (
                <div className="flex justify-center items-center">
                  <FaHandshake
                    className="ml-2 text-green-600"
                    title="Negotiable"
                    size={20}
                  />
                  <p className="text-center text-green-500 font-bold ml-2">
                    Price is negotiable
                  </p>
                </div>
              )}

              {currentUserIsTheSeller ? (
                <div className="flex justify-between items-center w-full mt-3">
                  <button
                    className="btn btn-secondary flex items-center"
                    style={{ width: "calc(50% - 5px)" }}
                    onClick={() => setIsEditingProduct(true)}
                  >
                    <FaEdit className="mr-2" /> Edit Product
                  </button>
                  <div style={{ width: "10px" }} />
                  <button
                    className="btn btn-error flex items-center"
                    style={{ width: "calc(50% - 5px)" }}
                    onClick={() => setConfirmDeletion(true)}
                  >
                    <FaTrash className="mr-2" /> Delete Product
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-primary btn-block mt-2"
                  onClick={updateCart}
                >
                  {!productInCart && <ShoppingCartIcon />}
                  {productInCart ? (
                    <BsCartCheckFill size={20} />
                  ) : (
                    `Ksh ${funcs.addComma(product.price)}`
                  )}
                </button>
              )}

              <div className="flex justify-center mt-4">
                <CopyUrlButton label="Product" />
              </div>

              <ChatButtons seller={product?.author} />

              {currentUserIsTheSeller && !user?.otherAccounts?.whatsapp && (
                <SellerWhatsAppNumberInput />
              )}

              {product?.shop?.deliveryInfo && (
                <div className="flex items-center my-8">
                  <BsTruck size={20} className="mr-3" />
                  <div>
                    <p className="text-1xl font-bold text-white-800">
                      Product Delivery Method
                    </p>
                    <p className="text-sm">{product?.shop?.deliveryInfo}</p>
                  </div>
                </div>
              )}

              <div className="flex justify-around w-full border-t-[0.5px] border-b-[0.5px] mt-3">
                <section
                  className="clickable-section flex flex-col items-center w-full cursor-pointer border-t-[1px] border-b-[1px] py-2"
                  onClick={navigateToShop}
                >
                  <HiHome className="text-2xl" />
                  <p>{product.shop.name}</p>
                </section>

                <section
                  className="clickable-section flex flex-col items-center w-full cursor-pointer border-t-[1px] border-b-[1px] py-2"
                  onClick={() =>
                    navigate(`/mart/profile/${product.author._id}`)
                  }
                >
                  <HiUser className="text-2xl" />
                  <p>{product.author.name}</p>
                </section>
              </div>
            </article>
          </section>

          {renderProductListSection("Related Products", relatedProducts)}
          {renderProductListSection("Recommended Products", samePriceProducts)}
        </article>
      </section>
    </LoadingIndicator>
  );
};

const renderProductListSection = (title: string, products: Product[]) => (
  <>
    {Boolean(products.length) && (
      <p className="text-1xl mt-8 font-bold text-white-800 mb-3">{title}</p>
    )}
    <HorizontalProductList products={products} />
  </>
);

export default ProductDetailsPage;
