import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

interface Props {
  tabs: { label: string; Page: JSX.Element }[];
}

const TopBar = ({ tabs }: Props) => {
  const [activeTab, setActiveTab] = useState(tabs[0]?.label || "");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabLabel = params.get("tab");

    const foundTab = tabs.find((tab) => tab.label === tabLabel);
    if (foundTab && activeTab !== foundTab.label) {
      setActiveTab(foundTab.label);
    }
  }, [location.search, activeTab, tabs]);

  const activateTab = (tabLabel: string) => {
    setActiveTab(tabLabel);
    navigate(`?tab=${tabLabel}`);
  };

  const renderLabelWithDescription = (label: string) => {
    switch (label) {
      case "Listings":
        return `${label} (2nd Hand)`;
      case "Products":
        return `${label} (New)`;
      case "Requests":
        return `${label} (Not Found)`;
      default:
        return label;
    }
  };

  return (
    <div>
      <div className="w-full border-b border-gray-300 overflow-x-auto mb-2">
        <div className="flex justify-center space-x-6 whitespace-nowrap px-4 w-max">
          {tabs.map((tab) => (
            <button
              key={tab.label}
              onClick={() => activateTab(tab.label)}
              className={`relative py-3 px-6 font-semibold text-sm whitespace-nowrap ${
                activeTab === tab.label ? "text-blue-500" : "text-gray-600"
              }`}
            >
              {renderLabelWithDescription(tab.label)}
              {activeTab === tab.label && (
                <div className="absolute inset-x-0 bottom-0 border-b-4 border-blue-500"></div>
              )}
            </button>
          ))}
        </div>
      </div>

      <div>
        {tabs.map((tab) => (
          <div
            key={tab.label}
            className={`${activeTab === tab.label ? "block" : "hidden"}`}
          >
            {tab.Page}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopBar;
