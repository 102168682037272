import { useState } from "react";

import { Input } from "../components";
import { sendMail } from "../services/mail";
import { useShops } from "../hooks";

const AdminPage = () => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { shops } = useShops();

  const send = async () => {
    const sellersEmail = shops
      .map((s) => s.author?.email)
      .filter((email) => typeof email === "string" && email);

    setLoading(true);
    const res = await sendMail({
      intro: message,
      name: "Amazing Seller",
      subject,
      to: sellersEmail,
    });
    setLoading(false);

    if (res.ok) {
      setMessage("");
      setSubject("");
    }
  };

  return (
    <>
      <h1 className="text-center">Admin Page</h1>
      <p>Send Email to Sellers</p>
      <Input
        onChange={setSubject}
        placeholder="Enter subject"
        value={subject}
      />
      <Input
        onChange={setMessage}
        placeholder="Enter message"
        value={message}
      />
      <button onClick={send} className="btn btn-secondary mt-4">
        {loading ? "Sending..." : "Send"} Message
      </button>
    </>
  );
};

export default AdminPage;
