import { ListingsPage, ProductsPage, RequestsPage } from "../../pages";
import TabList from "../TabList";

const TopBar = () => {
  const tabs = [
    { label: "Products", Page: <ProductsPage /> },
    { label: "Listings", Page: <ListingsPage /> },
    { label: "Requests", Page: <RequestsPage /> },
  ];

  return <TabList tabs={tabs} />;
};

export default TopBar;
